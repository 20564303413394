const merchants = [
  {
    "id": "decathlon-at",
    "country_code": "AT",
    "timezone": "Europe/Vienna",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-au",
    "country_code": "AU",
    "timezone": "Australia/Sydney",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-be",
    "country_code": "BE",
    "timezone": "Europe/Brussels",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-bg",
    "country_code": "BG",
    "timezone": "Europe/Sofia",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ca",
    "country_code": "CA",
    "timezone": "America/Toronto",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod": "https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod": "https://oneff-front.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ch",
    "country_code": "CH",
    "timezone": "Europe/Zurich",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-04.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ci",
    "country_code": "CI",
    "timezone": "Africa/Abidjan",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-cl",
    "country_code": "CL",
    "timezone": "America/Santiago",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-co",
    "country_code": "CO",
    "timezone": "America/Bogota",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-cz",
    "country_code": "CZ",
    "timezone": "Europe/Prague",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-04.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-de",
    "country_code": "DE",
    "timezone": "Europe/Berlin",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-04.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-dz",
    "country_code": "DZ",
    "timezone": "Africa/Algiers",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ee",
    "country_code": "EE",
    "timezone": "Europe/Tallinn",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.oneom-qualification.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-eg",
    "country_code": "EG",
    "timezone": "Africa/Cairo",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-es",
    "country_code": "ES",
    "timezone": "Europe/Madrid",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-07.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-fr",
    "country_code": "FR",
    "timezone": "Europe/Paris",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-05.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-gb",
    "country_code": "GB",
    "timezone": "Europe/London",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-gr",
    "country_code": "GR",
    "timezone": "Europe/Athens",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-hk",
    "country_code": "HK",
    "timezone": "Asia/Hong_Kong",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-hr",
    "country_code": "HR",
    "timezone": "Europe/Zagreb",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-hu",
    "country_code": "HU",
    "timezone": "Europe/Budapest",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-id",
    "country_code": "ID",
    "timezone": "Asia/Jakarta",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.oneom-qualification.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ie",
    "country_code": "IE",
    "timezone": "Europe/Dublin",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-il",
    "country_code": "IL",
    "timezone": "Asia/Jerusalem",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-it",
    "country_code": "IT",
    "timezone": "Europe/Rome",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-03.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-jp",
    "country_code": "JP",
    "timezone": "Asia/Tokyo",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ke",
    "country_code": "KE",
    "timezone": "Africa/Nairobi",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-kh",
    "country_code": "KH",
    "timezone": "Asia/Phnom_Penh",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-lt",
    "country_code": "LT",
    "timezone": "Europe/Vilnius",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-lv",
    "country_code": "LV",
    "timezone": "Europe/Riga",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ma",
    "country_code": "MA",
    "timezone": "Africa/Casablanca",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-mt",
    "country_code": "MT",
    "timezone": "Europe/Malta",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-mx",
    "country_code": "MX",
    "timezone": "America/Mexico_City",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-my",
    "country_code": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-nl",
    "country_code": "NL",
    "timezone": "Europe/Amsterdam",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-03.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ph",
    "country_code": "PH",
    "timezone": "Asia/Manila",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-pl",
    "country_code": "PL",
    "timezone": "Europe/Warsaw",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-08.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-pt",
    "country_code": "PT",
    "timezone": "Europe/Lisbon",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-03.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ro",
    "country_code": "RO",
    "timezone": "Europe/Bucharest",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-rs",
    "country_code": "RS",
    "timezone": "Europe/Belgrade",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-se",
    "country_code": "SE",
    "timezone": "Europe/Stockholm",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-sg",
    "country_code": "SG",
    "timezone": "Asia/Singapore",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-si",
    "country_code": "SI",
    "timezone": "Europe/Ljubljana",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-sk",
    "country_code": "SK",
    "timezone": "Europe/Bratislava",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-sn",
    "country_code": "SN",
    "timezone": "Africa/Dakar",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-th",
    "country_code": "TH",
    "timezone": "Asia/Bangkok",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-tn",
    "country_code": "TN",
    "timezone": "Africa/Tunis",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-tr",
    "country_code": "TR",
    "timezone": "Europe/Istanbul",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-04.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-tw",
    "country_code": "TW",
    "timezone": "Asia/Taipei",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-ua",
    "country_code": "UA",
    "timezone": "Europe/Kiev",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-us",
    "country_code": "US",
    "timezone": "America/Los_Angeles",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod": "https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod": "https://oneff-front.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-vn",
    "country_code": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-za",
    "country_code": "ZA",
    "timezone": "Africa/Johannesburg",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-africa-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlon-activities",
    "country_code": "FR",
    "timezone": "Europe/Paris",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlonactivities-fr",
    "country_code": "FR",
    "timezone": "Europe/Paris",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlonactivities-gb",
    "country_code": "FR",
    "timezone": "Europe/Paris",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlongiftcard-be",
    "country_code": "BE",
    "timezone": "Europe/Brussels",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "decathlontravel-fr",
    "country_code": "FR",
    "timezone": "Europe/Paris",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-02.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-06.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "loadtest-01",
    "country_code": "XX",
    "timezone": "Europe/Paris",
    "envs": ['preprod']
  },
  {
    "id": "akmall-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "argos-gb",
    "country_code": "GB",
    "timezone": "Europe/London",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "asda-gb",
    "country_code": "GB",
    "timezone": "Europe/London",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "ebay-gb",
    "country_code": "GB",
    "timezone": "Europe/London",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "debenhams-gb",
    "country_code": "GB",
    "timezone": "Europe/London",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-01.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "cdon-se",
    "country_code": "SE",
    "timezone": "Europe/Stockholm",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.oneom-qualification.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "coupang-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "galaxus-ch",
    "country_code": "CH",
    "timezone": "Europe/Zurich",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-europe-02.ig1.gke-prod-eu-01.dcp-eu-01-prod-lmm7.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "manor-ch",
    "country_code": "CH",
    "timezone": "Europe/Zurich",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-europe-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}"
           }
          }
        ]
      }
    }
  },
  {
    "id": "gmarket-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "kakaogift-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "lazada-my",
    "country_code": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "lottemart-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "lotteon-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "mercadolibre-mx",
    "country_code": "MX",
    "timezone": "America/Mexico_City",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "naversmartstore-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "shopee-my",
    "country_code": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "ssg-kr",
    "country_code": "KR",
    "timezone": "Asia/Seoul",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-asia-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-asia-01.ig1.gke-prod-as-01.dcp-asia-01-prod-4o5k.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff-sg.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "target-us",
    "country_code": "US",
    "timezone": "America/Los_Angeles",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod": "https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod": "https://oneff-front.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "walmart-mx",
    "country_code": "MX",
    "timezone": "America/Mexico_City",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-forward",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod":"https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod":"https://front.oneff.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  },
  {
    "id": "walmart-us",
    "country_code": "US",
    "timezone": "America/Los_Angeles",
    "envs": ['preprod','prod'],
    "options": {
      "order": {
        "actions": [
          {
            "id": "GO_TO_CAMUNDA",
            "name": "Go to Camunda",
            "icon": "mdi-cogs",
            "url": {
              "preprod":"https://camunda-oneom-america-01.ig1.gke-stg-eu-01.dcp-eu-01-stg-0qn4.decathlon.io/camunda/app/cockpit/default/#/dashboard",
              "prod":"https://camunda-oneom-america-01.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/camunda/app/cockpit/default/#/dashboard"
            }
          },
          {
            "id": "GO_TO_POSDATA",
            "name": "Go to Posdata",
            "icon": "mdi-xml",
            "url": {
              "preprod":"https://dashboard.posdata-qualification.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}",
              "prod":"https://dashboard.posdata.decathlon.net/share/{\"searchQueryStr\":\"transaction.retailTransaction.specialOrderNumber={{order.reference}}\",\"beginDate\":\"{{order.creation_date_time}}\"}"
            }
          },
          {
            "id": "GO_TO_ONEFF",
            "name": "Go to OneFF",
            "icon": "mdi-dolly",
            "url": {
              "preprod": "https://oneff.preprod.decathlon.net/orders/{{order.source.merchant_id}}/{{order.reference}}",
              "prod": "https://oneff-front.ig1.gke-prod-us-01.dcp-us-01-prod-gv2l.decathlon.io/orders/{{order.source.merchant_id}}/{{order.reference}}"
            }
          }
        ]
      }
    }
  }
]

const isMerchantIdSupported = (env,merchantId) => {
  let result = false

  merchants.some( (el) => {
    if (el.id === merchantId && el.envs.includes(env)) {
      result = true
      return result
    }
  })

  return result
}

export {
  merchants,
  isMerchantIdSupported,
}
